// src: https://brandfolder.com/brand-assets-0/
export interface PortalImageGroup {
  desktop: string;
  phone?: string;
}
export const PORTAL_ASSETS = {
  img: {
    controlCenter: <PortalImageGroup>{
      desktop: '/assets/images/controlcenter/desktop.jpg',
      phone: '/assets/images/controlcenter/phone.jpeg',
    },
    cityTruck: <PortalImageGroup>{
      desktop: '/assets/images/cityTruck/desktop.jpg',
      phone: '/assets/images/cityTruck/phone.jpg',
    },
    IT_technologist: <PortalImageGroup>{
      desktop: '/assets/images/IT_technologist/desktop.jpg',
    },
  },
  swagger: {
    'cdp-ltl-cust-shipmenttracking-api-v1_0': `${window.location.origin}/assets/swagger/cdp-ltl-cust-shipmenttracking-api-v1_0.json`,
    'cdp-ltl-cust-rating-api-v1_0': `${window.location.origin}/assets/swagger/cdp-ltl-cust-rating-api-v1_0.json`,
    'cdp-ltl-cust-shippingtools-api-v1_0': `${window.location.origin}/assets/swagger/cdp-ltl-cust-shippingtools-api-v1_0.json`,
  },
};
Object.freeze(PORTAL_ASSETS);
