import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { map } from 'rxjs';
import { API_URI } from '../api-store.tokens';
import { APIBase } from '../models/api-shared.interface';
import { AddBalance, WalletBalance } from './api-balance.interface';
import { DataDetail } from './api-details.interface';
import { APIProduct, UnsubscribeResponse } from './api-products.interface';
import { RatePlan } from './api-rates.interface';
import {
  DataSubscriptions,
  SubscribeToApi,
  SubscribedData,
  SubscriptionTypeData,
} from './api-subscriptions.interface';
import { StatsData } from './api-stats.interface';
import { UserCreated } from './api-user.interface';
import { SwaggerJsonRes } from './api-swagger-json.interface';

// TODO: add return types to functions
@Injectable({
  providedIn: 'root',
})
export class ApiWebdevPortalService {
  constructor(
    @Inject(API_URI) private apiUri: string,
    private injector: Injector
  ) {}

  private get httpClient() {
    return this.injector.get(HttpClient);
  }

  private url(endpoint: string) {
    return `${this.apiUri}/${endpoint}`;
  }

  private urlWebdev(endpoint: string) {
    return `${this.url('webdevportal/1.0/')}${endpoint}`;
  }

  getProducts() {
    const url = this.urlWebdev('products/list');
    return this.httpClient
      .get<APIBase<APIProduct[]>>(url)
      .pipe(map((res) => res.data));
  }

  getUserDetails() {
    const url = this.urlWebdev('user/appdetails');
    return this.httpClient
      .get<APIBase<DataDetail>>(url)
      .pipe(map((res) => res.data));
  }

  getBalance() {
    const url = this.urlWebdev('user/balance');

    return this.httpClient
      .get<APIBase<WalletBalance>>(url)
      .pipe(map((res) => res.data.amount));
  }

  getSubscriptions() {
    const url = this.urlWebdev('user/subscriptions');

    return this.httpClient
      .get<APIBase<DataSubscriptions>>(url)
      .pipe(map((res) => res.data?.developerSubscriptions));
  }

  getRatePlan(idPlan: string) {
    const url = this.urlWebdev(`products/rateplan/${idPlan}`);
    return this.httpClient
      .get<APIBase<RatePlan[]>>(url)
      .pipe(map((res) => res.data));
  }

  subscribeToApi(subscribeDetail: SubscribeToApi) {
    // TODO: Should ideally just be the apiProductName in the payload
    const url = this.urlWebdev(`user/subscriptions`);
    return this.httpClient.post<APIBase<SubscribedData>>(url, subscribeDetail);
  }

  generateBalanceToken() {
    const url = this.urlWebdev(`user/payment/generatetoken`);
    return this.httpClient
      .post<APIBase<{ referenceNumber: string }>>(url, {})
      .pipe(map((res) => res.data.referenceNumber));
  }

  addBalanceFromCard(addBalance: AddBalance) {
    const url = this.urlWebdev(`user/payment/addfromcard`);
    return this.httpClient.post(url, addBalance);
  }

  unsubscribeFromApi(productName: string) {
    const url = new URL(this.urlWebdev(`user/subscriptions/unsubscribe`));
    url.searchParams.append('productId', productName);

    return this.httpClient.post<APIBase<UnsubscribeResponse>>(url.toString(), {
      productName,
    });
  }

  userStats() {
    const url = this.urlWebdev('user/stat');
    return this.httpClient
      .get<APIBase<StatsData>>(url)
      .pipe(map((res) => res.data));
  }

  checkAndCreateUser() {
    // Needed check prior to calling the balance and subscriptions endpoints. Ensures user is in Apigee.
    const url = this.urlWebdev('user/checkAndCreateUser');
    return this.httpClient
      .post<APIBase<UserCreated>>(url, {})
      .pipe(map((res) => res.data));
  }

  userInfo() {
    const url = this.urlWebdev('user/getUserInfo');
    return this.httpClient
      .get<APIBase<SubscriptionTypeData>>(url)
      .pipe(map((res) => res.data));
  }

  getSwagger(url: string) {
    return this.httpClient.get<APIBase<SwaggerJsonRes>>(url).pipe(
      map((res) => {
        return {
          swaggerUrl: res.data.swaggerUrl,
          swaggerJson: JSON.parse(res.data.swaggerJson),
        };
      })
    );
  }
}
