import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  shareReplay,
  tap,
  throwError,
} from 'rxjs';
import { LoaderSpinnerComponent } from './loader-spinner/loader-spinner.component';

@Injectable()
export class LoaderService {
  private overlayRef: OverlayRef | undefined;
  private _isLoading$ = new BehaviorSubject(false);
  readonly isLoading$ = this._isLoading$.pipe(shareReplay());

  constructor(private injector: Injector) {}

  load$<T>(obs$: Observable<T>) {
    this.open();
    return obs$.pipe(
      tap(() => {
        this.close();
      }),
      catchError((error) => {
        this.close();
        return throwError(() => error);
      })
    );
  }

  open(): void {
    this.close();
    this._isLoading$.next(true);
    const overlay = this.injector.get(Overlay);
    this.overlayRef = overlay.create(<OverlayConfig>{
      height: '100%',
      width: '100%',
    });

    const userProfilePortal = new ComponentPortal(LoaderSpinnerComponent);
    this.overlayRef.attach(userProfilePortal);
  }

  close(): void {
    this._isLoading$.next(false);

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }
}
