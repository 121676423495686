import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { TranslationConfig } from './translate.model';
import { FALLBACK_LANGUAGE, PATH_TRANSLATIONS } from './translate.tokens';
import { HttpClient } from '@angular/common/http';

const savedLanguageKey = 'savedLanguage';

@Injectable({
  providedIn: 'root',
})
export class LanguageStateService {
  readonly _currentLanguage$ = new BehaviorSubject<string>('');

  constructor(private injector: Injector) {
    this.use(this.savedLanguage || this.fallbackLanguage);
  }

  get fallbackLanguage(): string {
    return this.injector.get<string>(FALLBACK_LANGUAGE);
  }

  get pathTranslations(): string {
    return this.injector.get<string>(PATH_TRANSLATIONS);
  }

  use(language: string) {
    this._currentLanguage$.next(language);
  }

  get chosenLanguage(): string {
    return this._currentLanguage$.getValue();
  }

  saveLanguage(language: string): void {
    if (!language) return;
    localStorage.setItem(savedLanguageKey, language);
  }

  fetchTranslations$(urlJson: string) {
    return this.httpClient
      .get<TranslationConfig>(urlJson, {
        responseType: 'json',
      })
      .pipe(
        map((translation: TranslationConfig) => {
          const messages = translation.messages;
          return messages;
        })
      );
  }

  get savedLanguage(): string {
    return localStorage.getItem(savedLanguageKey) || '';
  }

  private get httpClient() {
    return this.injector.get(HttpClient);
  }
}
