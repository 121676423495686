export class GetHasHelper {
  static get(obj: any, path: any, defaultValue = undefined) {
    const travel = (regexp: any) =>
      String.prototype.split
        .call(path, regexp)
        .filter(Boolean)
        .reduce(
          (res, key) => (res !== null && res !== undefined ? res[key] : res),
          obj
        );
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
    return result === undefined || result === obj ? defaultValue : result;
  }

  static has(obj: any, key: any): any {
    var keyParts = key.split('.');

    return (
      !!obj &&
      (keyParts.length > 1
        ? GetHasHelper.has(obj[key.split('.')[0]], keyParts.slice(1).join('.'))
        : GetHasHelper.hasOwnProperty.call(obj, key))
    );
  }
}
