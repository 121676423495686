export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBIo-Gnt7bOdJLouRxWEeDzmBkI-h90JjY',
    authDomain: 'xpo-ltl-dev-cf3b6.firebaseapp.com',
    databaseURL: 'https://xpo-ltl-dev-cf3b6.firebaseio.com',
    projectId: 'xpo-ltl-dev',
    storageBucket: 'xpo-ltl-dev.appspot.com',
    messagingSenderId: '339239167082',
    appId: '1:339239167082:web:fba5949733f56a2bf29613',
  },
};
