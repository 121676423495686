import { InjectionToken, NgModule } from '@angular/core';
import { API_URI, CONNECT_API_URI } from '@ltl-core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

const domainProvider = (provide: InjectionToken<string>, name: string) => {
  return {
    provide,
    useFactory: (config: ConfigManagerService) => config.get(name),
    deps: [ConfigManagerService],
    multi: true,
  };
};

@NgModule({
  providers: [
    domainProvider(API_URI, 'apiUrl'),
    domainProvider(CONNECT_API_URI, 'connectApiUrl'),
  ],
})
export class LtlApiStoreModule {}
