import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  constructor(private update: SwUpdate, private appRef: ApplicationRef) {}

  init(): void {
    if (!this.update.isEnabled) {
      return;
    }
    console.info('Auto update is enabled');

    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );
    const everyHour$ = interval(60 * 60 * 1000);

    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    concat(appIsStable$, everyHour$).subscribe(() => {
      this.update.checkForUpdate();
      console.info('Checking for updates');
    });
    // TODO: Update this method to versionUpdates observable on latest PWA version
    this.update.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))
      )
      .subscribe(() => {
        document.location.reload();
      });
  }
}
