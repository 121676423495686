import { CanActivateFn } from '@angular/router';
import { featureFlag } from '../constants/feature-flag';
import { Injector, inject } from '@angular/core';
import { UserService } from '../services/user.service';
import { map } from 'rxjs';

export const booleanGuard = (letPass: boolean): CanActivateFn => {
  return () => letPass;
};

export const isMonetizedGuard = (): CanActivateFn => {
  return () => {
    if (featureFlag.useMonetizedCheck) {  //ToDo : This condition can be removed later when monetization is part of the app.
      const injector = inject(Injector);
      const userService = injector.get(UserService);
      return userService.isMonetized$;
    }
    return false;
  };
};
