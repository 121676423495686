import { Routes } from '@angular/router';
import { PARAM_NAME, PORTAL_PATHS, PORTAL_PATHS_AUX } from './core/PATHS.const';
import { SidenavConfig, drawerOutletName } from './core/shell/shell.service';
import { isMonetizedGuard } from './shared/guards/boolean.guard';
import { xpoAuthCanMatch } from '@xpo-ltl/ngx-auth';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./core/shell/shell.component').then((m) => m.ShellComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: PORTAL_PATHS.home,
      },
      {
        path: PORTAL_PATHS.home,
        loadComponent: () =>
          import('./home/home.component').then((m) => m.HomeComponent),
      },
      {
        path: PORTAL_PATHS_AUX.balance,
        outlet: drawerOutletName,
        loadComponent: () =>
          import('./my-account/balance/balance.component').then(
            (m) => m.BalanceComponent
          ),
        data: {
          [drawerOutletName]: <SidenavConfig>{
            position: 'end',
            disableClose: true,
          },
        },
        canMatch: [xpoAuthCanMatch(), isMonetizedGuard()],
      },
      {
        path: PORTAL_PATHS_AUX.feedback,
        outlet: drawerOutletName,
        loadComponent: () =>
          import('./core/shell/feedback/feedback.component').then(
            (m) => m.FeedbackComponent
          ),
        data: {
          [drawerOutletName]: <SidenavConfig>{
            position: 'end',
          },
        },
        canMatch: [xpoAuthCanMatch(), isMonetizedGuard()],
      },
      {
        path: PORTAL_PATHS_AUX.mobileMenu,
        outlet: drawerOutletName,
        loadComponent: () =>
          import('./core/shell/nav/mobile-menu/mobile-menu.component').then(
            (m) => m.MobileMenuComponent
          ),
        data: {
          [drawerOutletName]: {
            position: 'start',
            panelClass: 'exDev-Shell-fixedPanel',
          },
        },
      },
      {
        path: PORTAL_PATHS.apiCatalog,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: PORTAL_PATHS.home,
          },
          {
            path: PORTAL_PATHS.home,
            loadComponent: () =>
              import('./api-catalog/api-catalog.component').then(
                (m) => m.ApiCatalogComponent
              ),
          },
          {
            path: `${PORTAL_PATHS.documentation}/:${PARAM_NAME.idApi}`,
            loadComponent: () =>
              import('./documentation/documentation.component').then(
                (m) => m.DocumentationComponent
              ),
          },
        ],
      },
      {
        path: PORTAL_PATHS.myAccount,
        loadComponent: () =>
          import('./my-account/my-account.component').then(
            (m) => m.MyAccountComponent
          ),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: PORTAL_PATHS.subscriptions,
          },
          {
            path: PORTAL_PATHS.apiUsage,
            loadComponent: () =>
              import('./my-account/dashboard/dashboard.component').then(
                (m) => m.DashboardComponent
              ),
            canMatch: [xpoAuthCanMatch()],
          },
          {
            // TODO: View transition in Angular 17
            path: PORTAL_PATHS.subscriptions,
            loadComponent: () =>
              import('./my-account/subscriptions/subscriptions.component').then(
                (m) => m.SubscriptionsComponent
              ),
            canMatch: [xpoAuthCanMatch()],
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: PORTAL_PATHS.home,
    pathMatch: 'full',
  },
];
