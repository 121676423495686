import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { LoaderSpinnerComponent } from './loader-spinner/loader-spinner.component';
import { LoaderService } from './loader.service';

@NgModule({
  imports: [LoaderSpinnerComponent, MatDialogModule, OverlayModule],
  providers: [LoaderService],
})
export class LoaderModule {}
