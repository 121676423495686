import { Component, ViewEncapsulation } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'ltlc-loader-spinner',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  templateUrl: './loader-spinner.component.html',
  styleUrls: ['./loader-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ltlc-LoaderSpinner cdk-overlay-dark-backdrop',
  },
})
export class LoaderSpinnerComponent {}
