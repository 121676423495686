import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { PwaService } from './shared/services/pwa.service';
import { Router } from '@angular/router';
import { UserService } from './shared/services/user.service';
import { take } from 'rxjs';
import { PORTAL_PATHS } from './core';

// TODO: Implement some form of analytics
@Component({
  selector: 'exDev-root',
  template: '<router-outlet/>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private pwaService: PwaService,
    private router: Router,
    private userService: UserService
  ) {
    this.pwaService.init();
  }

  ngOnInit(): void {
    this.handleInitialRouting();
  }

  handleInitialRouting() {
    this.userService.user$.pipe(take(1)).subscribe((user) => {
      if (!user || user.isMonetized || !user.isDashboardUser) {
        return;
      }
      const dashboardPath = `${PORTAL_PATHS.myAccount}/${PORTAL_PATHS.apiUsage}`;
      this.router.navigate([dashboardPath]);
    });
  }
}
