export interface TranslationConfig {
  language: string; // ISO
  messages: TranslationMessages;
}

export interface TranslationMessages {
  [key: string]: TranslationMessages | string;
}

export const iso639 = new Map<string, string>();
iso639.set('en', 'en-US').set('fr', 'fr-CA').set('es', 'es-ES');
