import { Injectable } from '@angular/core';
import { PORTAL_PATHS } from '../../PATHS.const';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  readonly userLinks = {
    apiCatalog: {
      home: `/${PORTAL_PATHS.apiCatalog}/${PORTAL_PATHS.home}`,
      documentation: `/${PORTAL_PATHS.apiCatalog}/${PORTAL_PATHS.documentation}`,
    },
    myAccount: {
      subscriptions: `/${PORTAL_PATHS.myAccount}/${PORTAL_PATHS.subscriptions}`,
      usage: `/${PORTAL_PATHS.myAccount}/${PORTAL_PATHS.apiUsage}`,
    },
  };
  readonly links = [
    {
      name: 'navHome',
      path: '/' + PORTAL_PATHS.home,
    },
    {
      name: 'navAPICatalog',
      path: this.userLinks.apiCatalog.home,
    },
  ];
}
