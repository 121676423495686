import { Injectable } from '@angular/core';
import { XpoAuthenticationService } from '@xpo-ltl/ngx-auth';
import { combineLatest, map } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiWebdevPortalService, SubscriptionTypeTier } from '@ltl-core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userInfo$ = combineLatest([
    this.apiWebdevPortalService.userInfo(),
    this.xpoAuthenticationService.user$,
  ]).pipe(shareReplay(1));

  readonly user$ = this.userInfo$.pipe(
    map(([subscriptionType, user]) => {
      if (!user) {
        return null;
      }

      const isMonetized =
        subscriptionType.tier === SubscriptionTypeTier.XPO_PLATINUM;
      const isDashboardUser =
        subscriptionType.tier === SubscriptionTypeTier.XPO_PREFFERED;

      const newUser = {
        ...user,
        profile: {
          email: user.info.email,
          name: user.info.name,
        },
        isMonetized,
        isDashboardUser,
      };

      return newUser;
    })
  );

  isMonetized$ = this.user$.pipe(
    map((user) => !!user?.isMonetized),
    shareReplay(1)
  );

  isDashboardUser$ = this.user$.pipe(
    map((user) => !!user?.isDashboardUser),
    shareReplay(1)
  );

  constructor(
    private xpoAuthenticationService: XpoAuthenticationService,
    private apiWebdevPortalService: ApiWebdevPortalService
  ) {}
}
