export const PORTAL_PATHS = {
  home: 'home',
  apiCatalog: 'api-catalog',
  myAccount: 'my-account',
  pricing: 'pricing',
  profile: 'profile',
  subscriptions: 'manage-subscriptions',
  apiUsage: 'api-usage',
  documentation: 'documentation',
};
Object.freeze(PORTAL_PATHS);

export const PORTAL_PATHS_AUX = {
  balance: 'balance',
  mobileMenu: 'mobile-menu',
  feedback: 'feedback',
};
Object.freeze(PORTAL_PATHS_AUX);

export const PARAM_NAME = {
  idApi: 'apiId',
  redirectApp: 'redirectApp',
};
Object.freeze(PARAM_NAME);
