import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, shareReplay, take } from 'rxjs';
import { UserService } from '../../shared/services/user.service';
import { PARAM_NAME, PORTAL_PATHS_AUX } from '../PATHS.const';

export const drawerOutletName = 'drawerOutlet';

export interface SidenavConfig {
  position?: 'end' | 'start';
  panelClass?: string;
  disableClose?: boolean;
}
const initialSidenavConfig: SidenavConfig = {
  position: <'end' | 'start'>'end',
};
@Injectable({
  providedIn: 'root',
})
export class ShellService {
  readonly drawerOutletName = drawerOutletName;
  private _sideNavConfig$ = new BehaviorSubject<SidenavConfig>(
    initialSidenavConfig
  );
  readonly sideNavConfig$ = this._sideNavConfig$.pipe(shareReplay(1));
  closeBeforeWarning$?: (() => Observable<boolean>) | undefined;

  constructor(private router: Router, private userService: UserService) {}

  addCreditIfMonetized(idApi: string): void {
    this.userService.isMonetized$.pipe(take(1)).subscribe((isMonetized) => {
      if (isMonetized) {
        this.lazyLoadSidenav(PORTAL_PATHS_AUX.balance, {
          [PARAM_NAME.idApi]: idApi,
        });
      }
    });
  }

  lazyLoadSidenav(path: string, queryParams: Params = {}) {
    this.router.navigate(
      [
        {
          outlets: {
            [this.drawerOutletName]: [path],
          },
        },
      ],
      { queryParams }
    );
  }

  removeOutletAndNavigate(path?: string) {
    this.router
      .navigate([{ outlets: { [this.drawerOutletName]: null } }])
      .then(() => {
        if (path) {
          this.router.navigate([path]);
        }
      });
  }

  setSidenavSettings(sidenavConfig: SidenavConfig): void {
    if (!sidenavConfig) {
      return;
    }
    const newConfig: SidenavConfig = {
      ...initialSidenavConfig,
      ...sidenavConfig,
    };

    this._sideNavConfig$.next(newConfig);
  }
}
