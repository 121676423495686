export interface DeveloperSubscription {
  id: string;
  appName: string;
  appKey: string;
  name: string;
  apiProduct: string;
  displayName: string;
  endTime: string;
  createdAt: string;
}

export interface DataSubscriptions {
  developerSubscriptions: DeveloperSubscription[];
  error: null;
}

export interface SubscribeToApi {
  email: string;
  appName: string;
  productName: string;
  balanceCredit: BalanceCredit;
}

export interface BalanceCredit {
  billingType: string;
  currencyCode: string;
  units: number;
}

export interface SubscribedData {
  name: string;
  apiproduct: string;
  createdAt: string;
  error: null;
}

export enum SubscriptionTypeTier {
  XPO_PLATINUM = 'XpoPlatinum',
  XPO_PREFFERED = 'XpoPreferred',
}

export interface SubscriptionTypeData {
  tier: SubscriptionTypeTier;
}
