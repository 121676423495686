import { NgModule, isDevMode } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LtlApiStoreModule } from './core/ltl-api-store/ltl-api-store.module';
import { HttpClientModule } from '@angular/common/http';
import { LoaderModule, translateProvider } from '@ltl-core';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { xpoConfigManagerProvider } from '@xpo-ltl/config-manager';
import { xpoAuthConfigManagerConfiguration } from '@xpo-ltl/ngx-auth';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    LoaderModule,
    LtlApiStoreModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    xpoConfigManagerProvider(
      xpoAuthConfigManagerConfiguration({
        firebaseOptions: environment.firebase,
        configFileUrl: './../assets/config.json',
      })
    ),
    translateProvider('en', './../../assets/i18n/'),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
