import { APP_INITIALIZER, Provider } from '@angular/core';
import { FALLBACK_LANGUAGE, PATH_TRANSLATIONS } from './translate.tokens';
import { TranslateService } from './translate.service';
import { combineLatest, take } from 'rxjs';
import { LanguageStateService } from './language-state.service';

export const translateProvider = (
  fallbackLanguage: string,
  rootFolderTranslations: string
): Provider => {
  return [
    {
      provide: FALLBACK_LANGUAGE,
      useValue: fallbackLanguage,
    },
    {
      provide: PATH_TRANSLATIONS,
      useValue: rootFolderTranslations,
    },
    {
      multi: true,
      provide: APP_INITIALIZER,
      deps: [TranslateService, LanguageStateService],
      useFactory:
        (translate: TranslateService, translateState: LanguageStateService) =>
        () => {
          return new Promise((resolve) => {
            const loads$ = [translate.loadTranslations$(fallbackLanguage)];
            if (
              translateState.savedLanguage &&
              translateState.savedLanguage !== fallbackLanguage
            ) {
              loads$.push(
                translate.loadTranslations$(translateState.savedLanguage)
              );
            }
            combineLatest(loads$)
              .pipe(take(1))
              .subscribe(() => {
                resolve(null);
              });
          });
        },
    },
  ];
};
