import { Injectable } from '@angular/core';
import { map, of, shareReplay, switchMap, tap } from 'rxjs';
import { LanguageStateService } from './language-state.service';
import { iso639 } from './translate.model';
import { GetHasHelper } from './getHas.helper';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  translationMap = new Map<string, Record<string, any>>();
  childTranslationsMap = new Map<string, Record<string, any>>();

  currentLanguage$ = this.languageStateService._currentLanguage$.pipe(
    switchMap((lang: string) => {
      if (lang && !this.translationMap.has(lang)) {
        return this.loadTranslations$(lang);
      }
      return of(lang);
    }),
    tap((lang) => {
      if (lang) {
        this.saveLanguage(lang);
      }
    }),
    shareReplay(1)
  );

  constructor(private languageStateService: LanguageStateService) {}

  loadTranslations$(lang: string) {
    const url = `${this.languageStateService.pathTranslations}${lang}.json`;
    return this.languageStateService.fetchTranslations$(url).pipe(
      tap((messages) => {
        this.translationMap.set(lang, messages);
      }),
      map(() => lang)
    );
  }

  addTranslations(lang: string, messages: Record<string, any>): void {
    this.childTranslationsMap.set(lang, messages);
    const currentMessages = this.translationMap.get(lang);
    if (currentMessages) {
      // Add child to parent
      for (const key in messages) {
        if (Object.prototype.hasOwnProperty.call(messages, key)) {
          if (!Object.prototype.hasOwnProperty.call(currentMessages, key)) {
            currentMessages[key] = messages[key];
          }
        }
      }

      this.translationMap.set(lang, currentMessages);
    }
  }

  instant(key: string, opts?: Record<string, string>): string {
    if (!key) return key;
    return this.get(key, opts) || key;
  }

  getISOLanguageName(): string {
    //  ISO 639-1
    return iso639.get(this.chosenLanguage) || 'en-US';
  }

  use(lang: string): void {
    this.languageStateService.use(lang);
  }

  saveLanguage(lang: string): void {
    this.languageStateService.saveLanguage(lang);
  }

  get fallbackLanguage(): string {
    return this.languageStateService.fallbackLanguage;
  }

  get chosenLanguage(): string {
    return this.languageStateService.chosenLanguage;
  }

  private get(key: string, opts?: Record<string, string>): string {
    const currentLanguage = this.chosenLanguage;
    let translation = '',
      translations;

    if (this.childTranslationsMap.has(currentLanguage)) {
      const childTranslations = this.childTranslationsMap.get(currentLanguage);
      if (GetHasHelper.has(childTranslations, key)) {
        translations = this.childTranslationsMap.get(currentLanguage);
      }
    }

    if (!translations && this.translationMap.has(currentLanguage)) {
      translations = this.translationMap.get(currentLanguage);
    }

    const hasTranslation =
      !!translations && GetHasHelper.has(translations, key);
    if (this.translationMap.has(this.fallbackLanguage) && !hasTranslation) {
      translations = this.translationMap.get(this.fallbackLanguage);
    }

    if (translations && GetHasHelper.has(translations, key)) {
      translation = GetHasHelper.get(translations, key);
      translation = this.substitute(translation, opts);
    }

    return translation || key;
  }

  private substitute(
    translation: string,
    opts?: Record<string, string>
  ): string {
    if (translation && opts) {
      let doubleBraceCloseIndex = translation.lastIndexOf('}}'),
        singleBraceCloseIndex = translation.lastIndexOf('}'),
        doubleBraceOpenIndex = translation.lastIndexOf('{{'),
        singleBraceOpenIndex = translation.lastIndexOf('{'),
        indexOpen = -1,
        indexClose = -1;
      while (
        ![
          doubleBraceCloseIndex,
          singleBraceCloseIndex,
          doubleBraceOpenIndex,
          singleBraceOpenIndex,
        ].every((n) => n === -1)
      ) {
        if (doubleBraceCloseIndex !== -1) {
          indexClose = doubleBraceCloseIndex + 1;
        }
        if (indexClose === -1 && singleBraceCloseIndex !== -1) {
          indexClose = singleBraceCloseIndex;
        }
        if (indexClose !== -1) {
          indexOpen = -1;
        }
        if (doubleBraceOpenIndex !== -1) {
          indexOpen = doubleBraceOpenIndex;
        }
        if (indexOpen === -1 && singleBraceOpenIndex !== -1) {
          indexOpen = singleBraceOpenIndex;
        }
        if (indexOpen !== -1 && indexClose !== -1) {
          const substring = translation.substring(indexOpen, indexClose + 1);
          Object.entries(opts).forEach(([name, value]) => {
            if (substring.includes(name)) {
              translation = translation.replace(substring, value);
            }
          });
          indexOpen = -1;
          indexClose = -1;
          doubleBraceCloseIndex = translation.lastIndexOf('}}');
          singleBraceCloseIndex = translation.lastIndexOf('}');
          doubleBraceOpenIndex = translation.lastIndexOf('{{');
          singleBraceOpenIndex = translation.lastIndexOf('{');
        }
      }
    }

    return translation?.trim();
  }
}
